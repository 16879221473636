import { useEffect, useState } from 'react'
import { Box, Accordion, AccordionSummary, AccordionDetails, Radio } from '@mui/material'
import { styled } from '@mui/material/styles'

import { useGlossary } from '@hooks/index'

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  width: '100%',
}))

const AccordionZone = styled(props => <Accordion {...props} />)(({ theme, isSelected }) => ({
  gridColumn: isSelected ? 'span 2' : '1fr',
  borderRadius: `${theme.spacing(1)} !important`,
  overflow: 'hidden',
  margin: '0 !important',
  boxShadow: 'none',
  '& .MuiAccordionSummary-content': {
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: '0 !important',
    marginTop: '0 !important',
  },
  '& .MuiAccordionSummary-root': {
    minHeight: '0px',
  },
  '& .Mui-expanded': {
    minHeight: '0px !important',
  },
  '&::before': {
    backgroundColor: 'white',
  },
}))

const AccordionSummaryZone = styled(props => <AccordionSummary {...props} />)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  font: theme.font.roboto.paragraph.small,
  padding: '0px !important',
  '& .MuiRadio-root': {
    padding: '0 !important',
  },
}))

const AccordionDetailsZone = styled(props => <AccordionDetails {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}))

const SectionContainer = styled(props => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginLeft: theme.spacing(2),
}))

const BoxNoResult = styled(props => <Box {...props} />)(({ theme }) => ({
  font: theme.font.roboto.paragraph.small,
  display: 'flex',
  padding: theme.spacing(1),
  fontWeight: '600',
}))

const ZoneSelector = ({ type, zone, setZone, setSection, section, zones, error, error1 }) => {

  const { glossary } = useGlossary()

  const [selectedZone, setSelectedZone] = useState(null)
  const [selectedSection, setSelectedSection] = useState(null)
  const [key, setKey] = useState(0)

  const sortByAlphaNumeric = (a, b) => {
    const regex = /^(\D+)\s*(\d+)(\D*)$/
    const aMatch = a.name.match(regex)
    const bMatch = b.name.match(regex)
  
    /*
     *Separamos mediante texto, números y sufijos
    */
    if (aMatch && bMatch) {
      const aText = aMatch[1].trim()
      const bText = bMatch[1].trim()
  
      const textComparison = aText.localeCompare(bText)
      if (textComparison !== 0) return textComparison
  
      const aNumber = parseInt(aMatch[2], 10)
      const bNumber = parseInt(bMatch[2], 10)
      if (aNumber !== bNumber) return aNumber - bNumber
  
      const aSuffix = aMatch[3].trim()
      const bSuffix = bMatch[3].trim()
      return aSuffix.localeCompare(bSuffix)
    }
    // Si no se tiene la estructura texto, número y sufijo, se compara de forma básica
    return a.name.localeCompare(b.name)
  }

  useEffect(() => {
    setSelectedZone(zone)
    setKey(key+1)
  }, [zone])

  useEffect(() => {
    setSelectedSection(section)
    setKey(key+1)
  }, [section])


  return (
    <Container key={key}>
      { zones?.length > 0 ?
        zones.sort(sortByAlphaNumeric).map(item => (
          item.sections.length > 0 ?
            <AccordionZone
              key={item.id}
              isSelected={selectedZone && item.id === selectedZone.id}
              expanded={selectedZone && item.id === selectedZone.id}
              onClick={e => {
                setZone(item)
                setSelectedZone(item)
                if (item !== selectedZone) {
                  setSelectedSection(null)
                  setSection(undefined)
                }
              }}
            >
              <AccordionSummaryZone>
                <span
                  style={{
                    backgroundColor: `#${item.color}`,
                    width: 16,
                    height: 43,
                    borderTopLeftRadius: 8,
                    borderBottomLeftRadius: 8,
                  }}
                ></span>
                <Radio
                  sx={{
                    color: error1 ? 'red' : 'black',
                    '&.Mui-checked': {
                      color: 'black',
                    },
                  }}
                  checked={selectedZone && item.id === selectedZone.id}
                />
                <span
                style={{
                  color: error1 ? 'red' : 'inherit',
                }}
                >{item.name}</span>
              </AccordionSummaryZone>
              <AccordionDetailsZone>
                {item.sections.filter(s => s.typeId===type)
                .sort(sortByAlphaNumeric)
                .map(itemSection => (
                  <SectionContainer
                    key={itemSection.id}
                    // eslint-disable-next-line no-unused-vars
                    onClick={e => {
                      setSection(itemSection)
                      setSelectedSection(itemSection)
                    }}
                  >
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: 'black',
                        },
                      }}
                      checked={selectedSection && itemSection.id === selectedSection.id}
                    />
                    <span>{itemSection.name}</span>
                  </SectionContainer>
                ))}
              </AccordionDetailsZone>
            </AccordionZone>
            : <></>
        ))
        : []
      }
      { zones && zones.length === 0 && <BoxNoResult>{ glossary('NoZoneOfType') }</BoxNoResult> }
    </Container>
  )
}

export default ZoneSelector
