import { CustomInput } from '@App/Components'
import { useState } from 'react'

const SimplePhoneTextField = ({ label, onChange, initialValue, error, ...props }) => {

  const [value, setValue] = useState(initialValue)

  const isPhoneValid = phone => {
    const regex = /^\d{0,15}$/
    return regex.test(phone)
  }

  const handleChange = e => {
    if(!isPhoneValid(e.target.value)) return
    setValue(e.target.value)
    onChange(e.target.value)
  }

  return <CustomInput
    label={label}
    onChange={handleChange}
    name='phone'
    value={value}
    error={error}
    {...props}
  />
}

export default SimplePhoneTextField
