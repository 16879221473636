import { useContext, useEffect, useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'

import { Context as LocaleContext } from '@features/Locale'
import { useDebounce, useForum } from '@hooks/index'

const VenueAutocomplete = ({ customstyles, venue, setVenue, error }) => {

  const locale = useContext(LocaleContext)
  const { getForums } = useForum()

  const [venueOptions, setVenueOptions] = useState([])
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce(query)

  const fetchVenuesData = () => {
    const variables = {
      includeMap: true,
      num: 30,
      pag: 0,
      query,
    }
    getForums(variables).then(resp => {
      setVenueOptions(resp.edges.map(edge => edge.node))
    })
  }

  useEffect(() => {
    fetchVenuesData()
  }, [debouncedQuery])

  return (
    <Autocomplete
      options={venueOptions}
      value={venue}
      fullWidth
      getOptionLabel={option =>
        option == null ? '' : typeof option === 'string' ? option : option.name ? `${option?.name}` : ''
      }
      getOptionSelected={option => venue === option.id}
      onChange={(event, value) => {
        value ? setVenue(value) : setVenue(null)
      }}
      sx={{ ...customstyles }}
      renderInput={params => (
        <TextField
          {...params}
          variant='outlined'
          label={locale.glossary.Venue}
          fullWidth
          value={query}
          onChange={e => setQuery(e.target.value)}
          error={error}
        />
      )}
    />
  )
}

export default VenueAutocomplete
