import { useEffect, useLayoutEffect, useState } from 'react'
import { Autocomplete, Box, Button, InputAdornment, Paper, Popover, Popper, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FlagIcon } from 'react-flag-kit'

import { ChevronDownIcon, SearchIcon } from '../Icons'
import { useCountry, useGlossary } from '@features/App/hooks'

const Root = styled(props => <Box {...props} />)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  })
)

const MainContainer = styled(props => <Box {...props} />)(
  ({ theme }) => ({
    display: 'flex',
    border: `1px solid ${theme.palette.gray[300]}`,
    borderRadius: theme.spacing(0.5),
  })
)

const Label = styled(Box)(({ theme }) => ({
  font: theme.font.roboto.paragraph.medium,
  fontWeight: '600',
}))

const FlagContainer = styled(props => <Button {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.gray[200],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    borderTopLeftRadius: theme.spacing(0.5),
    borderBottomLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0),
    borderBottomRightRadius: theme.spacing(0),
    gap: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.gray[200],
    },
    '& .MuiTouchRipple-root': {
      color: theme.palette.gray[300],
    },
  })
)

const PhoneTextField = styled(props => <TextField {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.gray[100],
    fontWeight: 500,
    borderRadius: theme.spacing(0.5),
    width: '100%',
    '& .MuiInputBase-root': {
      border: 'none',
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    },
    '& input': {
      border: 'none',
      font: theme.font.roboto.paragraph.medium,
      padding: 0,
    },
    '& fieldset': {
      display: 'none',
    },
  })
)

const FloatBox = styled(props => <Box {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.gray[100],
    boxShadow: theme.shadows.elevation.large,
    borderRadius: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
  })
)

const StyledAutocomplete = styled(props => <Autocomplete {...props} />,
  { shouldForwardProp: prop => prop !== 'classes' })(
  ({ theme }) => ({
    borderBottom: `2px solid ${theme.palette.gray[200]}`,
    '& .MuiInputBase-root': {
      border: 'none',
    },
    '& fieldset': {
      border: 'none',
    },
  })
)

const StyledPaper = styled(props => <Paper {...props} />)(
  ({ theme }) => ({
    boxShadow: 'none',
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
    position: 'static !important',
    transform: 'translate(0, 0) !important',
    '& *::-webkit-scrollbar': {
      width: theme.spacing(0.5),
      backgroundColor: 'transparent',
    },
    '& *::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      backgroundColor: 'rgba(180,180,180,0.2)',
    },
  })
)

const StyledPopper = styled(props => <Popper {...props} />)(
  ({ theme }) => ({
    boxShadow: 'none',
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
    position: 'static !important',
    transform: 'translate(0, 0) !important',
  })
)

const OptionMenu = styled(props => <li {...props}/>)(
  ({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1),
    '&[aria-selected="true"]': {
      backgroundColor: `${theme.palette.secondary[40]} !important`,
    },
  })
)

const PhoneField = ({ initialLada, ladaOnChange, reset, initialPhone, phoneOnChange, onChange, ...props }) => {

  const { glossary } = useGlossary()
  const { getCountries } = useCountry()

  const [allCountries, setAllCountries] = useState([])
  const [width, setWidth] = useState(0)
  const [anchor, setAnchor] = useState(null)
  const [openOptions, setOpenOptions] = useState(false)
  const [phoneValue, setPhoneValue] = useState(initialPhone)
  const [ladaValue, setLadaValue] = useState(initialLada!=='52' ?
    {}
    : {
      id: 'MX',
      name: 'México',
      dialCode: '52',
    })

  const open = Boolean(anchor)

  const isPhoneValid = phone => {
    const regex = /^(\d\s?){0,11}$/
    return regex.test(phone)
  }

  const handleClick = event => {
    setAnchor(anchor ? null : event.currentTarget)
  }

  useLayoutEffect(() => {
    const ele = document.getElementById('main_container_lada')
    setWidth(ele.offsetWidth)
  })

  useEffect(() => {
    getCountries({
      num: 300,
      asc: true,
    })
      .then(resp => {
        const _allCountries = resp.edges.map(edge => edge.node)
        setAllCountries(_allCountries)
        const foundCountry = _allCountries.find(c => c.dialCode == initialLada)
        if(foundCountry) setLadaValue(foundCountry)
      })
  }, [])

  useEffect(() => {
    if (reset) {
      setPhoneValue('')
      setLadaValue(
        initialLada === '52'
          ? { id: 'MX', name: 'México', dialCode: '52' }
          : {}
      )
    }
  }, [reset, initialLada])

  useEffect(() => {
    if(open) setTimeout(() =>  setOpenOptions(true), 200)
    else setOpenOptions(false)
  }, [open])

  return <Root>
    <Label>
      { glossary('Phone') }
    </Label>
    <MainContainer id='main_container_lada'>
      <FlagContainer
        onClick={handleClick}
      >
        <FlagIcon
          code={ladaValue.id}
          width='20'
          height= '17'
        />
        <ChevronDownIcon sx={{ width: '16px', height: '16px' }}/>
      </FlagContainer>
      <PhoneTextField
        onChange={e => {
          if(!isPhoneValid(e.target.value)) return
          if(phoneOnChange) phoneOnChange(e.target.value)
          if(onChange) onChange(ladaValue.dialCode, e.target.value)
          setPhoneValue(e.target.value)
        }}
        value={phoneValue}
        InputProps={{
          startAdornment: <InputAdornment position='start'>
          +{ladaValue.dialCode}
          </InputAdornment>,
        }}
        {...props}
      />
    </MainContainer>
    <Popover
      open={open}
      anchorEl={anchor}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={e => setAnchor(null)}
      sx={{ marginTop: '8px', transform: 'translateX(-1px)' }}
    >
      <FloatBox  sx={{ width: `${width}px` }}>
        <StyledAutocomplete
          disableClearable
          value={ladaValue}
          onChange={(event, newValue) => {
            if(ladaOnChange) ladaOnChange(newValue.dialCode)
            if(onChange) onChange(newValue.dialCode, phoneValue)
            setLadaValue(newValue)
            setOpenOptions(false)
          }}
          disablePortal={true}
          renderOption={(props, option) => (
            <OptionMenu
              {...props}>
              <FlagIcon code={option.id} size='16'/>
              {`${option.name} (+${option.dialCode})`}
            </OptionMenu>
          )}
          renderInput={params => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: <InputAdornment position='start'>
                  <SearchIcon/>
                </InputAdornment>,
                endAdornment: null,
              }}
            />
          )}
          open={openOptions}
          options={allCountries}
          getOptionLabel={ option =>  !option?.name ? '' : `${option.name} (+${option.dialCode})`}
          size='small'
          PaperComponent={StyledPaper}
          PopperComponent={StyledPopper}
          {...props}
        />
      </FloatBox>
    </Popover>
  </Root>
}

export default PhoneField
