import VenueAutocomplete from './VenueAutocomplete'
import ZoneSelector from './ZoneSelector'
import CustomTextField from './CustomTextField'
import PhoneField from './PhoneField'
import SimplePhoneTextField from '@components/Forms/SimplePhoneTextField'

export {
  VenueAutocomplete,
  ZoneSelector,
  CustomTextField,
  PhoneField,
  SimplePhoneTextField,
}

export default {
  VenueAutocomplete,
  ZoneSelector,
  CustomTextField,
  PhoneField,
  SimplePhoneTextField,
}
